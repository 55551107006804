/*  ==========================================================================
    COLORS
    List of all colors concerning your project.

    INFO:
    - change your project related colors here
    - if needed change also the specific colors
    - don't delete colors, only add (if possible here: custom)!
    - for naming help of additional custom colors use
      "http://chir.ag/projects/name-that-color/#000000"
    ========================================================================== */

/*
 * GENERAL
 */

$clr__black: #000000;
$clr__bn-red: #AE173B;
$clr__gray: #808080;
$clr__white: #FFFFFF;

/*
 * PROJECT RELATED
 */

// corporate identity
$clr__ci--primary: #C7C7C7;
$clr__ci--secondary: #D0BD9C;
$clr__ci--tertiary: #c7c7c74d;
$clr__ci--test: rgba(199, 199, 199, 0.2);


// typography
$clr__border--primary: #626262;
$clr__typo--secondary: #FFFFFF;
$clr__typo--primary: #626262;

// call to action
$clr__cta--primary: #843B3F;


// notification
$clr__correct: #008000;
$clr__error: #FF0000;

/*
 * SPECIFIC
 */

$clr__page: $clr__white;
$clr__progress-bar: $clr__bn-red;
$clr__scrollbar: $clr__bn-red;
$clr__selection: $clr__bn-red;


/*
 * CUSTOM
 */
$clr__red: #843B3F;
$clr__ci--third: #E2AF4E;
$clr__btn--primary:#843B3F;
$clr__accent--primary:#843B3F;
$clr__border--primary:#626262;
$clr__custom--color: #D0BD9C;
$clr__custom--white: #fff;
$clr__newsletter--background: rgba(199, 199, 199, 0.01);
$clr__room-box-shadow: rgba(0, 0, 0, 0.16);
$clr_room-list-shadow: rgba(0, 0, 0, 0.16);

/*
 * Social Media Wall
 */
$clr__smw--background: rgb(199, 199, 199);
$clr__smw--background__opacity: rgba(199, 199, 199, 0.3);
$clr__smw--button__primary: #626262;
$clr__smw--button__secondary: #843B3F;