/*  ==========================================================================
    IMPRESSIONS V3
    ========================================================================== */

/*
 * COLORS
 */

$module-clr__impressions-v3--arrows: $clr__ci--primary;
$module-clr__impressions-v3--arrows-hover: $clr__ci--secondary;

/*
 * MODULE
 */

.bn-impressions-v3 {
  margin-top: 30px;
  .dropbtn {
    display: none;
  }
  .bn-grid__spacing-bottom--impressions {
    padding-bottom: 40px !important;
  }
  .bn-impressions-v3__arrows {
    @include slider--arrows-v2($module-clr__impressions-v3--arrows,$module-clr__impressions-v3--arrows-hover);
    margin-bottom: -30px;
  }
  .bn-impressions-v3__slider {
    margin-top: 30px;
    @include clearfix();
    overflow: hidden;
    .bn-impressions-v3__image {
      display: block;
      float: left;
      padding: 0 10px;
      width: 33.33%;
      > div {
        overflow: hidden;
      }
      img {
        @include img--responsive();
      }
    }
  }
  .bn-impressions-v3__titel {
    display: flex;
    flex-wrap: wrap;
  }
  .bn-arrows__arrow--deactivate {
    display: none !important;
  }
  .bn-impressions-v3__slider--deactivate {
    visibility: hidden;
    height: 0;
    margin: 0;
  }
  .bn-impressions-v3__filter {
    text-transform: uppercase;
    font-size: 2em;
    padding-right: 20px;
    padding-left: 10px;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
  .bn-impressions-v3__filter--act {
    text-decoration: underline;
  }
}


/* RESPONSIVE BEHAVIOR
 * --------------------------------------------------------------------------- */

// phablet
@media #{$mq__phablet} {
  .bn-impressions-v3 {
    .bn-impressions-v3__slider {
      .bn-impressions-v3__image {
        padding: 0 5px;
        width: 50%;
      }
    }
    .dropbtn {
      display: block;
      text-transform: uppercase;
      font-size: 2em;
      border: none;
      cursor: pointer;
      .dropdown__titel {
        text-decoration: underline;
      }
      .bn-icon {
        text-decoration: none;
        margin-left: 20px;
      }
    }

    .bn-impressions-v3__titel {
      position: relative;
      display: inline-block;
      cursor: pointer;
    }

    .dropdown-content {
      display: none;
      position: absolute;
      background-color: #f9f9f9;
      min-width: 160px;
      box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.6);
      z-index: 1;
    }

    .dropdown-content a {
      color: black;
      padding: 12px 16px;
      text-decoration: none;
      display: block;
    }

    .dropdown-content a:hover {background-color: #f1f1f1}

    .bn-impressions-v3__titel:hover .dropdown-content {
      display: flex;
      flex-direction: column;
    }
    .bn-impressions-v3__filter--act {
      display: none;
    }
    .bn-impressions-v3__filter {
      padding-left: 0px;
    }
  }
}

// phone
@media #{$mq__phone} {
  .bn-impressions-v3 {
    .bn-impressions-v3__slider {
      .bn-impressions-v3__image {
        float: none;
        padding: 0;
        width: 100%;
      }
    }
    .bn-impressions-v3__arrows {
      margin-bottom: -25px !important;
    }
  }
}