/*
 * COLORS
 */

$module-clr__smw--background__opacity: $clr__smw--background__opacity;
$module-clr__smw--background: $clr__smw--background;
/*
 * MODULE
 */
.bn-grid__smw {
  padding: 0 40px;
  max-width: 1575px;
  margin: auto;
}

.bn-smw {
  .crt-feed-container{
    .crt-post{
      .crt-post-content {
        background-color: $module-clr__smw--background__opacity;
        position: relative;
        .crt-social-icon {
          background-color: $module-clr__smw--background;
          position: absolute;
          right: 0;
          bottom: 295px;
          color: $clr__white;
          width: 50px;
          height: 50px;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          top: 0%;
        }
        .crt-post-header {
          background-color: $module-clr__smw--background;
          margin-bottom: 20px;
          padding-top: unset;
          position: static;
        }
        .crt-post-content-text {
          font-size: 15px;
        }
      }
      .crt-post-footer {
        display: none;
      }
      .crt-post-fullname {
        padding: 15px 0;
        background-color: $module-clr__smw--background;
        text-transform: uppercase;
        position: relative;
        font-size: 14px;
      }
      .crt-post-fullname a {
        color: $clr__white;
      }
      .crt-social-icon i {
        color: $clr__white;
      }
    }
    .crt-load-more {
      text-align: unset;
    }
    .crt-load-more a {
      color: $clr__smw--button__primary;
      font-size: 15px;
      text-transform: uppercase;
      text-decoration: underline;
      &:hover {
        color: $clr__smw--button__secondary;
      }
    }

  }
}
.crt-feed.crt-feed-branded {
  .crt-logo {
    display: none;
  }
}

.crt-post-name {
  visibility: hidden;
}