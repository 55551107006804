/*  ==========================================================================
    TYPOGRAPHY
    Font settings concerning your project.

    INFO:
    - change your project related font family here
    - change secondary font family if you want a different font for headlines
    - fonts are included local via "webfontloader.js", look here: main.js
    - for help how to include a font local look here:
      "Resources/Public/admin/assets/fonts/README.txt"
    - if needed change also the font weight's
    ========================================================================== */

// font families
$typo__ff--primary: 'Roboto', sans-serif;
$typo__ff--secondary: 'Roboto', sans-serif;

// font sizes
$typo__fs--button: 1.6em;;
$typo__fs--headline: 4.5em;
$typo__fs--headline-tablet: 3.5em;
$typo__fs--headline-phablet: 3.0em;
$typo__fs--headline-phone: 2.5em;
$typo__fs--subheadline: 2.5em;
$typo__fs--subheadline-phablet: 2.2em;
$typo__fs--subheadline-phone: 2.0em;
$typo__fs--text: 1.8em;

// font weights
$typo__fw--bold: 700;
$typo__fw--light: 300;
$typo__fw--regular: 400;